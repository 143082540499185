import Vue from 'vue'
import Router from 'vue-router'
import Welcome from './components/welcome.vue'
import CompanyDomain from './components/companyDomain.vue'
import cfg from './components/cfg.vue'
import UsersList from './components/usersList.vue'
import PhishingChannels from './components/phishingChannels.vue'
import withoutDomain from './components/withoutDomain.vue'
import Templates from './components/templates.vue'
Vue.use(Router)
const routes = [
  {
    name: 'Welcome',
    component: Welcome,
    path: '/'
  },
  {
    name: 'CompanyDomain',
    component: CompanyDomain,
    path: '/domain'
  },
  {
    name: 'UsersList',
    component: UsersList,
    path: '/users'
  },
  {
    name: 'PhishingChannels',
    component: PhishingChannels,
    path: '/phishing-template'
  },
  {
    name: 'withoutDomain',
    component: withoutDomain,
    path: '/without-domain'
  },
  {
    name: 'Templates',
    component: Templates,
    path: '/templates'
  },
  {
    name: 'cfg',
    component: cfg,
    path: '/cfg'
  }
];
const router = new Router({
  routes,
  mode: 'history'
});

export default router

