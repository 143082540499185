<template>
  <section class="section_wh companyNameSection" id="section2">
    <div class="overlaybox">
      <div class="welcomeHeading">
        <h2 class="secondary_h">Your Company Domain</h2>
        <small>Let The Quest Begin!</small>
      </div>
      <p>
        Your domain is the key to unlocking a personalized defense shield against precision strikes. Enter the domain name and witness the HumanFirewall AI in action.
      </p>
      <p><i class="fas fa-hand-point-right text-warning"></i>&nbsp;Example: google.com</p>
      <div class="inputbox">
        <input type="text" @keyup.enter="addDomain()" v-model="domainName"  class="companynamesearch form-control" placeholder="Enter the company domain name">
        <span class="text-danger">{{ domainerror }}</span>
      </div>
      <div class="withoutdomain">&nbsp;
        <router-link :to="{ name: 'withoutDomain'}">try without domain name</router-link>
      </div>
      <button class="continuebtn btn mb-0" @click="addDomain">Initiate Employee Finder</button>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CompanyDomain',
  data() {
    return {
      domainName: '',
      domainerror: '',
      isValid: false,
    }
  },
  methods: {
    validateDomain() {
      const completeURL = this.domainName.startsWith('http://') || this.domainName.startsWith('https://')
        ? this.domainName
        : 'http://' + this.domainName;
      const parsedUrl = new URL(completeURL);
      let hostname = parsedUrl.hostname.startsWith('www.') ? parsedUrl.hostname.slice(4) : parsedUrl.hostname;
      const domainRegex = /^(?:https?:\/\/)?(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;
      this.isValid = domainRegex.test(hostname);
    },
    extractDomainFromURL(url) {
      try {
        const completeURL = url.startsWith('http://') || url.startsWith('https://') ? url : 'http://' + url;
        const parsedUrl = new URL(completeURL);
        let hostname = parsedUrl.hostname.startsWith('www.') ? parsedUrl.hostname.slice(4) : parsedUrl.hostname;
        return hostname;
      } catch (error) {
        return null;
      }
    },
    addDomain() {
      this.validateDomain();
      if (this.domainName.trim() !== '') {
        const extractedDomain = this.extractDomainFromURL(this.domainName);
        if (extractedDomain && this.isValid) {
          localStorage.setItem('domainName', extractedDomain);
          this.$router.push({ name: 'UsersList' });
          this.domainName = '';
          this.domainerror = '';
        } else {
          this.domainerror = 'Please enter a valid domain name';
        }
      } else {
        this.domainerror = 'Please enter a domain name';
      }
    }
  }
}
</script>
