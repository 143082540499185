<template>
  <footer class="sectionfooter">
    <div class="copyright">Copyright &copy; 2023 HumanFirewall<sup>&reg;</sup>. All Rights Reserved.</div>
    <div class="hidetpc">Terms & Privacy Policy</div>
  </footer>
</template>
<script>
export default {
  name:'Footer'
}
</script>