<template>
  <div id="app">
    <Header></Header>
    <transition name="page" mode="out-in">
    <router-view :key="$route.fullPath"></router-view>
  </transition>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from './common/header.vue'
import Footer from './common/footer.vue'
export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      transitionName: null,
    }
  },
  watch: {
    $route(to, from) {
      if (from.name) {
        this.transitionName = 'page';
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 0);
      } else {
        this.transitionName = null;
      }
    },
  },
}
</script>
<style>
.page-enter-active, .page-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}

.page-enter, .page-leave-to{
  opacity: 0;
  transform: translateY(50%);
}
</style>

