<template>
  <section class="section_wh welcomesection" id="section1">
    <div class="overlaybox">
      <h1 class="welcomeHeading">
        <span>Welcome!</span>
        <small>To HumanFirewall Generative AI</small>
      </h1>
      <p>
        Unleash the extraordinary might of Generative AI to create an impenetrable shield, defending your invaluable employees against Needle Phishing attacks—precision strikes that exploit individual digital footprints.</p>
        <p><img src="../../public/static/images/cyber-security.png" style="position: relative; top: -2px; width: 16px;margin-right: 2px;">Dive into the future of cybersecurity with us!</p>
      <button class="continuebtn btn" @click="welcome">continue</button>
      <p class="Needle_p"><img src="../../public/static/images/dart.png" style="position: relative; top: -2px; width: 16px;margin-right: 2px;"><a class="linktext" href="https://www.linkedin.com/pulse/needle-phishing-ankush-johar-64e0e/">What's Needle Phishing? <i class="fas fa-external-link-alt"></i></a></p>
    </div>
  </section>
</template>
<script>
export default {
  name: 'Welcome',
  data() {
    return {
        
    }
  },
  mounted(){
    this.generateRandomID()
  },
  methods: {
    welcome() {
      this.$router.push({name:'CompanyDomain'})
    },
    generateRandomID() {
      // Generate a random ID using a combination of timestamp and random number
      let res = this.makeid(7)
      localStorage.setItem("uuid",res)
    },
    makeid(length) {
    let result = '';
    const characters = '123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
    }

 
  }
}
</script>

