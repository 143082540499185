<template>
  <div>
  <section v-for="(section, index) in configArray" :key=index class=" companyNameSection" >
    <div class="overlaybox" style="min-width: 50vw;">
      <div class="welcomeHeading">
        <h2 class="secondary_h">{{ index + 1  }} : {{ section.name }}</h2>
        
        
      </div>
      <p>
        {{ section.description }}
      </p>
      

      <!-- Resizable Text Box -->
      <div class="inputbox1" style="min-width: 40vw;">
        <textarea v-model="section.template" class="form-control" rows=20 placeholder="Specialized Topic Content"></textarea>
        <span class="text-danger">{{ domainerror }}</span>
      </div>
      <br>
      <br>
      <p>
        Please be mindful about {USERINFO}, {COMPANY},{COUNTRY}, {PREVIOUS_INFO} and other tags. Removing them can lead to skewed data. 
        Results from GPT's user interface has more optimizations and confidence factor than the api responses. Api requests need to forward
        contexts from previous prompt unlike GPT's user interface. 
        Please dont change any information about the format of the data. If in doubt, just reset the data and click save. 
      </p>


      <button class="continuebtn btn mb-0" @click="Save(index)">Save</button>
      <button class="continuebtn btn mb-0" @click="Reset(index)">Reset</button>

      <h4>
        <div v-if="sf"> 
        <p><strong>{{ this.success }}</strong></p>        
        </div>
        <div v-if="ef"> 
        <p><strong>{{ this.error }}</strong></p>        
        </div>
      </h4>

    </div>
  </section>

</div>  
</template>

<script>
import axios from 'axios';

export default {
  name: 'cfg',
  data() {
    return {
      domainName: '',
      originalDomainName: '', // To store the original text for reset
      domainerror: '',
      isValid: false,
      configArray : [{
        name: "Specialized Topic Template",
        description: "this is the template for topic generation, when an employee has been selected",
        template: "",
        url:"topic",
        default:`I am a cyber security awareness and training manager.
I work in at {COMPANY}  in {COUNTRY}.
I want to identify the top 12 training topics for cyber awareness for the following person in my company : {USERINFO}


What kind of phishing simulations should I do? 
Write me 12 examples of phishing simulations that are super relevant for this job title and job role,
for a person working in this organisation. My life depends on this selection.
Ensure that specialist simulated attacks are generated like an expert social engineer.
Mention the information about the kind of attack for the topics: Data Entry, Click Type, Reply based email attacks, QR code phishing type etc. `,
      },{
        name: "Specialized Phishing Template ",
        description: "This is the template to generate phishing attacks for the specialized topics generated",
        template:"",
        url:"message",
        default:`You are the Information Security Manager of your company assigned with the task to train all employees of your organization to be able to identify phishing attacks. 
To be able to do this you need to send very targeted simulated phishing attacks to employees periodically.

Generate phishing simulation for email,teams and sms for the topic {PREVIOUS_INFO} for the following employee.
The simulation should be targeted based on the details provided below. 
{USERINFO}

Every simulation should be of a different type and must be relevant to the recipient. Length of the body of the simulation should be at least 2 to 3 short paragraphs. The body should end with relevant and realistic email signature or email closing..

Also add a list 5 top pointers that makes the email a phishing attack. Give me response in the following format 
[{
        "TemplateType": teams or sms or email
        "Content": // Content of the template 
        "Subject": // Phishing subject of the template for email. This field is mandatory
	"TopPointers": // ["1- ","2- ","3- ","4- ","5- "] 

}]
Above is array of JSON. 3 items one for each type. Send me the JSON. It is an array at top level.
Dont share any other information before or after the array of jsons. All keys and values should be in quotes. All fields are mandatory.
`,
      },
      {
        name: "Generic Topic Template",
        description: "this is the template for topic generation for general case ",
        template: "",
        url:"gtopic",
        default:`STEP 1: 
I am a cyber security awareness and training manager.
I work in {INDUSTRY} industry  in {COUNTRY}.
I want to identify the top 12 training topics for cyber awareness for the following person in my company with the job title of: {JOB_TITLE}, of department {DEPARTMENT}

STEP 2: 
What kind of phishing simulations should I do? 
Write me 12 examples of phishing simulations that are super relevant for this job title and job role,
for a person working in this organisation. My life depends on this selection. 
Ensure that specialist simulated attacks are generated like an expert social engineer.
 Mention these details in the description Make Data Entry, Click Type, Reply based email attacks, QR code phishing type etc. 
`,
      },{
        name: "Generic Phishing Template ",
        description: "This is the template to generate phishing attacks for general case",
        template:"",
        url:"gmessage",
        default:`
As a security awareness manager, draft simulated phishing templates on the basis of following {PREVIOUS_INFO} specific for {JOB_TITLE} job role, {DEPARTMENT}, in {INDUSTRY}
Share the detailed template for the attack, my life depends on it. For this topic I need ONLY one phishing template  each for email ,sms and teams, so total 3 attacks.

Every simulation should be of a different type and must be relevant to the recipient. Length of the body of the simulation should be at least 2 to 3 short paragraphs. The body should start with Dear {First Name}  and should end with relevant and realistic email signature or email closing. Please .

Also add a list of 5 top pointers that makes the email a phishing attack. Give me response in the following format
[{
        "TemplateType": teams or sms or email
        "Content": // Content of the template
        "Subject": // Phishing subject of the template for email. This field is mandatory
        "TopPointers": // ["1- ","2- ","3- ","4- ","5- "]

}]
Above is array of JSON. 3 items one for each type. Send me the JSON. It is an array at top level.
Dont share any other information before or after the array of jsons. All keys and values should be in quotes. All fields are mandatory.`,
      }],
      success:"Action performed successfully",
      error : "Error in performing action",
      sf : false, 
    }
  },
  mounted(){
    this.FetchData()
  },
  methods: {
    async FetchData(){
      for (let index = 0; index < this.configArray.length; index++) {
        let element = this.configArray[index];
        if (element.url != ""){
          let result = await axios.get(element.url)
        this.configArray[index].template = result.data.Content
        }
        

        
      }
    },
    Get(){},
    Set(){},
    Reset(index) {
      // Reset the text to its original value
      this.configArray[index].template = this.configArray[index].default

    },
    async Save(index) {
 
      // Save the text for further processing if needed
      axios.post(this.configArray[index].url,this.configArray[index].template)


    }
  }
}
</script>
