import Vue from 'vue'
import App from './App.vue'
import router from './routers'
import axios from 'axios'

// axios.defaults.baseURL = 'http://localhost:3000/api/v1/';
axios.defaults.baseURL = 'https://humanfirewall.ai/api/v1/';


Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
