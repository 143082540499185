<template>
  <section class="section_wh channelSection">
    <div class="text-center progresssection" v-if="sortedArray.length < 12">
      <div class="blinkcontainer">
        <div class="dot"></div>
        <div class="pulse"></div>
      </div>
      <strong class="prog_counter">{{ roundNumber(progress, 0) }}%</strong>
      <div class="progress">
        <div class="progress-bar" role="progressbar" :style="{ width: progress + '%' }" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
      <div class="mt5">
        <div class="loaderText_change">
          <div v-if="sortedArray.length < 3 ">Still working… take a deep breath and relax</div>
          <div v-if="sortedArray.length > 3 && sortedArray.length < 6">Why not stretch your legs while you wait?</div>
          <div v-if="sortedArray.length > 6 && sortedArray.length < 9">Take a deep breath, relax and smile :-)</div>
          <div v-if="sortedArray.length > 9">Did you drink water today? Go gulp a glass of water while I’m still working…</div>
        </div>
        <div class="lightText">It should take 90 to 120 seconds to generate precision strikes</div>
      </div> 
    </div>
    <div v-if="sortedArray.length != 0" class="nav_tabs_ai  clearfix">
      <div  class="nav nav-tabs" id="nav-tab" role="tablist" style="position: relative;max-width: 1100px;margin: auto;z-index: 1000;justify-content: center;">
        <button class="nav-link active" id="email-tab" data-toggle="tab" data-target="#email" type="button" role="tab" aria-controls="email" aria-selected="true">Email</button>
        <button class="nav-link" id="teams-tab" data-toggle="tab" data-target="#teams" type="button" role="tab" aria-controls="teams" aria-selected="false">Teams</button>
        <button class="nav-link" id="whatsapp-tab" data-toggle="tab" data-target="#whatsapp" type="button" role="tab" aria-controls="whatsapp" aria-selected="false">WhatsApp</button>
      </div>
    </div>
    <div class="tab-content tab_content_ai" id="nav-tabContent">
      <div class="tab-pane fade show active" id="email" role="tabpanel" aria-labelledby="email-tab">
        <div class="ai_result_layout">
          <div v-if="sortedArray.length == 0">
            <div v-for="index in 12" :key="index" class="template-loader">
              <div class="dateplaceholder"></div>
              <div class="loader_con">
                <div class="n_lo w-50 m-0"></div>
                <div class="n_lo w-40 mx-0"></div>
                <div class="temp_load"></div>
              </div>
            </div>
          </div>
          <div class="results_ai" v-for="(result,index) in sortedArray" :key="index">
            <span class="indicator"></span>
            <div class="result_m" v-if="result.key === 0">January<span>2024</span></div>
            <div class="result_m" v-if="result.key === 1">February<span>2024</span></div>
            <div class="result_m" v-if="result.key === 2">March<span>2024</span></div>
            <div class="result_m" v-if="result.key === 3">April<span>2024</span></div>
            <div class="result_m" v-if="result.key === 4">May<span>2024</span></div>
            <div class="result_m" v-if="result.key === 5">June<span>2024</span></div>
            <div class="result_m" v-if="result.key === 6">July<span>2024</span></div>
            <div class="result_m" v-if="result.key === 7">August<span>2024</span></div>
            <div class="result_m" v-if="result.key === 8">September<span>2024</span></div>
            <div class="result_m" v-if="result.key === 9">October<span>2024</span></div>
            <div class="result_m" v-if="result.key === 10">November<span>2024</span></div>
            <div class="result_m" v-if="result.key === 11">December<span>2024</span></div>
            <div class="result_cont" v-for="(template, index) in (result.data).filter(template => (template.TemplateType ==='email') )" :key="index">
              <div class="result_cont_h">
                <h5>{{ template.Topic }}</h5>
                <p>Subject: {{ template.Subject }}</p>
              </div>
              <div class="result_pannel">
                <div class="pan_h">
                  <span>Template</span>
                  <span></span>
                </div>
                <div class="pan_con" v-html="formatContent(template.Content)"></div>
              </div>
              <div class="topicKeyList" v-if="template.Description != ''" v-html="formatContent(template.Description)"></div>
            </div>
          </div>     
        </div>
      </div>
      <div class="tab-pane fade" id="teams" role="tabpanel" aria-labelledby="teams-tab">
        <div class="ai_result_layout">
          <div v-if="sortedArray.length == 0">
            <div v-for="index in 12" :key="index" class="template-loader">
              <div class="dateplaceholder"></div>
              <div class="loader_con">
                <div class="n_lo w-50 m-0"></div>
                <div class="n_lo w-40 mx-0"></div>
                <div class="temp_load"></div>
              </div>
            </div>
          </div>
          <div class="results_ai" v-for="(result,index) in sortedArray" :key="index">
            <span class="indicator"></span>
            <div class="result_m" v-if="result.key === 0">January<span>2024</span></div>
            <div class="result_m" v-if="result.key === 1">February<span>2024</span></div>
            <div class="result_m" v-if="result.key === 2">March<span>2024</span></div>
            <div class="result_m" v-if="result.key === 3">April<span>2024</span></div>
            <div class="result_m" v-if="result.key === 4">May<span>2024</span></div>
            <div class="result_m" v-if="result.key === 5">June<span>2024</span></div>
            <div class="result_m" v-if="result.key === 6">July<span>2024</span></div>
            <div class="result_m" v-if="result.key === 7">August<span>2024</span></div>
            <div class="result_m" v-if="result.key === 8">September<span>2024</span></div>
            <div class="result_m" v-if="result.key === 9">October<span>2024</span></div>
            <div class="result_m" v-if="result.key === 10">November<span>2024</span></div>
            <div class="result_m" v-if="result.key === 11">December<span>2024</span></div>
            <div class="result_cont" v-for="(template, index) in (result.data).filter(template => (template.TemplateType ==='teams') )" :key="index">
              <div class="result_cont_h">
                <h5>{{ template.Topic }}</h5>
              </div>
              <div class="result_pannel">
                <div class="pan_h">
                  <span>Message</span>
                  <span></span>
                </div>
                <div class="pan_con" v-html="formatContent(template.Content)"></div>
              </div>
              <div class="topicKeyList" v-if="template.Description != ''" v-html="formatContent(template.Description)"></div>
            </div>
          </div>     
        </div>
      </div>
      <div class="tab-pane fade" id="whatsapp" role="tabpanel" aria-labelledby="whatsapp-tab">
        <div class="ai_result_layout">
          <div v-if="sortedArray.length == 0">
            <div v-for="index in 12" :key="index" class="template-loader">
              <div class="dateplaceholder"></div>
              <div class="loader_con">
                <div class="n_lo w-50 m-0"></div>
                <div class="n_lo w-40 mx-0"></div>
                <div class="temp_load"></div>
              </div>
            </div>
          </div>
          <div class="results_ai" v-for="(result,index) in sortedArray" :key="index">
            <span class="indicator"></span>
            <div class="result_m" v-if="result.key === 0">January<span>2024</span></div>
            <div class="result_m" v-if="result.key === 1">February<span>2024</span></div>
            <div class="result_m" v-if="result.key === 2">March<span>2024</span></div>
            <div class="result_m" v-if="result.key === 3">April<span>2024</span></div>
            <div class="result_m" v-if="result.key === 4">May<span>2024</span></div>
            <div class="result_m" v-if="result.key === 5">June<span>2024</span></div>
            <div class="result_m" v-if="result.key === 6">July<span>2024</span></div>
            <div class="result_m" v-if="result.key === 7">August<span>2024</span></div>
            <div class="result_m" v-if="result.key === 8">September<span>2024</span></div>
            <div class="result_m" v-if="result.key === 9">October<span>2024</span></div>
            <div class="result_m" v-if="result.key === 10">November<span>2024</span></div>
            <div class="result_m" v-if="result.key === 11">December<span>2024</span></div>
            <div class="result_cont" v-for="(template, index) in (result.data).filter(template => (template.TemplateType ==='sms') )" :key="index">
              <div class="result_cont_h">
                <h5>{{ template.Topic }}</h5>
              </div>
              <div class="result_pannel">
                <div class="pan_h">
                  <span>Message</span>
                  <span></span>
                </div>
                <div class="pan_con" v-html="formatContent(template.Content)"></div>
              </div>
              <div class="topicKeyList" v-if="template.Description != ''" v-html="formatContent(template.Description)"></div>
            </div>
          </div>     
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from 'axios';
export default {
  name: 'Templates',
  data() {
    return {
      templateArray: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      resultArray: [],
      sortedArray: [],
      templateloader: true,
      storedDataString: {},
      industryName: '',
      designationName: '',
      countryName: '',
      departmentName: '',
      progress:0
    }
  },
  created() {
    this.storedDataString = localStorage.getItem('withoutdomain');
    const storedData = JSON.parse(this.storedDataString);
    this.industryName = storedData.industryName;
    this.designationName = storedData.designationName;
    this.countryName = storedData.countryName;
    this.departmentName = storedData.departmentName
  },
  mounted() {
    this.startEngine()
  },
  methods: {
    roundNumber(number, decimals) {
      const multiplier = Math.pow(10, decimals || 0);
      return Math.round(number * multiplier) / multiplier;
    },
    formatContent(content) {
      return content.replace(/\n/g, '<br>');
    },
    async startEngine() {
      this.sortedArray=[];
      try {
        let uuid = localStorage.getItem("uuid")
        console.log(uuid)
        let result = await axios.get(`genginestart?uuid=${uuid},industry=${this.industryName}&department=${this.departmentName}&job=${this.designationName}&country=${this.countryName}`);
        if (result.data === 'OK') {
          this.startFetchingTemplate();
        }
      }
      catch (error) {
        console.error('Engine not started', error);
      }
    },
    async startFetchingTemplate() { 
      let that = this;
      const processedPositions = new Set();
      clearInterval(this.intr);
      this.intr = setInterval(async function () {
        for (let i = 0; i < that.templateArray.length; i++) {
          if (!processedPositions.has(i)) {
            let tempResult = await that.fetchTemplates(that.templateArray[i]);
            if (Object.keys(tempResult.data).length !== 0) {
              let obj = {};
              obj.key = i;
              obj.data = tempResult.data;
              that.resultArray.push(obj);
              that.resultArray.sort((a, b) => a.key - b.key);
              that.sortedArray =  that.resultArray
              processedPositions.add(i);
              that.progress = (processedPositions.size / that.templateArray.length) * 100;
            }
          }
        }
        if (processedPositions.size === that.templateArray.length) {
          clearInterval(that.intr);
        }
      }, 10000);
    },
    async fetchTemplates(i) {
      try {
        let tempResult = await axios.get(`engineprogress?pos=${i}`);
        if (tempResult && tempResult.data) {
          return tempResult;
        } else {
          console.error('Empty or undefined response:', tempResult);
          return { data: {} };
        }
      } catch (error) {
        console.error('Error fetching templates:', error);
        return { data: {} };
      }
    }

    // async fetchTemplates(i) {
    //   try {
    //      let tempResult = await axios.get(`engineprogress?pos=${i}`);
    //      return tempResult;
    //   } catch (error) {
    //     console.error('Error fetching user information:', error);
    //   }
    // }
  }
}
</script>
