<template>
  <section class="section_wh companyNameSection">
    <div class="overlaybox">
      <div class="welcomeHeading">
        <h2 class="secondary_h">Enter Following Details</h2>
        <small>Let The Quest Begin!</small>
      </div>
      <div class="form-group mt30" :class="{ 'has-error': !industryName && showError }">
        <label class="labeltext">Industry</label>
        <input type="text" class="form-control" placeholder="" v-model="industryName">
        <p v-if="!industryName && showError" class="error-message">Field name cannot be blank</p>
      </div>
      <div class="form-group" :class="{ 'has-error': !designationName && showError }">
        <label class="labeltext">Designation</label>
        <input type="text" class="form-control" placeholder="" v-model="designationName">
        <p v-if="!designationName && showError" class="error-message">Field name cannot be blank</p>
      </div>
      <div class="form-group">
        <label class="labeltext">Department (optional)</label>
        <input type="text" class="form-control" placeholder="" v-model="departmentName">
      </div>
      <div class="form-group" :class="{ 'has-error': !countryName && showError }">
        <label class="labeltext">Country</label>
        <input type="text" class="form-control" placeholder="" v-model="countryName">
        <p v-if="!countryName && showError" class="error-message">Field name cannot be blank</p>
      </div>
      <button class="continuebtn btn mb10 attackbtn" @click="validateAndSubmit">Generate Attacks* Now!</button>
      <p class="smalltext m-0">*simulation attacks for training purposes only</p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'withoutDomain',
  data() {
    return {
      industryName: '',
      designationName: '',
      countryName: '',
      defaultdepartmentName: 'department not specified',
      departmentName: '',
      showError: false 
    };
  },
  methods: {
    validateAndSubmit() {
      this.showError = true;
      if (
        this.industryName.trim() === '' ||
        this.designationName.trim() === '' ||
        this.countryName.trim() === ''
      ) {
        return;
      }
      if (this.departmentName.trim() === '') {
        this.departmentName = this.defaultdepartmentName;
      }
      const dataObject = {
        industryName: this.industryName,
        designationName: this.designationName,
        countryName: this.countryName,
        departmentName: this.departmentName
      };

      localStorage.setItem('withoutdomain', JSON.stringify(dataObject));
      this.$router.push({ name: 'Templates' });
    }
  }
};
</script>

<style scoped>
.has-error input {
  border-color: red; /* Adjust the style for error state as needed */
}

.error-message {
  color: red;
  margin-top: 5px;
}
</style>
