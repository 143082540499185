<template>
  <header class="headersection">
    <div class="gradientbox"></div>
    <nav class="pageheader">
      <div>
        <a href="/"><img src="../../public/static/images/antiphishGPT_logo.png" alt="logo" class="logo_c"></a>
      </div>
      <div class="userLogin hide">
        <!-- <span>Sunita Kandwal</span><img class="gb_n" src="../../public/static/images/5x.jpg" alt=""> -->
      </div>
    </nav>
  </header>
</template>
<script>
  export default {
    name: 'Header',
  }
</script>
