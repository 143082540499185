<template>
  <section class="section_wh userdetailsSection">
    <div style="margin-bottom: 30px!important;" v-if="error" class="alert alert-danger alert-dismissible fade show w-50 m-auto" role="alert">{{ error }}  <router-link :to="{ name: 'CompanyDomain'}">Start again</router-link>
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <h2 class="secondary_h">Below are some employees with diverse backgrounds</h2>
    <div class="container">
      <!-- Loader Section -->
      <div v-if="loader" class="row row-cols-1 justify-content-center justify-content-sm-start row-cols-sm-2 row-cols-md-3 row-cols-lg-5 row-cols-xl-5">
        <div class="col" v-for="n in 5" :key="n">
          <div class="userCard userCardLoader">
            <div class="progresscard-loader cardLoader">
              <div class="card-loader">
                <div class="userimgloader"></div>
                <div v-for="i in 3" :key="i" class="n_lo" :class="{ 'w-50': i === 1 }"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- User Cards Section -->
      <div v-else class="row row-cols-1 justify-content-center justify-content-sm-start row-cols-sm-2 row-cols-md-3 row-cols-lg-5 row-cols-xl-5">
        <div class="col" v-for="(user, index) in users.slice(0, 5)" :key="index">
          <div class="userCard">
            <div class="userCardheader" :style="{ backgroundColor: user.color }"></div>
            <div class="userCard-body">
              <img v-if="user.photo_url === ''" src="../../public/static/images/userdefault.png" class="userimage" alt="">
              <img v-else :src="user.photo_url" class="userimage" alt="">
              <div class="text-center">
                <h5 class="userCard-title">{{ user.name }}</h5>
                <div class="usertitle opacity-75">{{ user.title }}</div>
                <div class="opacity-75 line_height_18">
                  <div class="block text-capitalize">{{ user.subdepartments && user.subdepartments.length > 0 ? user.subdepartments[0].replace(/_/g, ' ') : '' }}</div>{{ user.country }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Continue Button -->
      <button class="continuebtn btn mb10 attackbtn" @click="startEngine">Generate Attacks* Now!</button>
      <p class="smalltext">*simulation attacks for training purposes only</p>
    </div>
  </section>
</template>

<script>
import axios from 'axios';

export default {
  name: 'UsersList',
  data() {
    return {
      users: [],
      companyDomain: '',
      loader: true,
      error: '', // Changed from errorMessage to error for consistency
    };
  },
  mounted() {
    this.generateRandomID();
    this.companyDomain = localStorage.getItem('domainName');
    this.fetchUserInfo();
  },
  methods: {
    async fetchUserInfo() {
      try {
        let uuid = localStorage.getItem("uuid");
        let result = await axios.get(`userInfo?company=${this.companyDomain}&uuid=${uuid}`);

        if (result.status === 200) {
          this.users = result.data.map(user => ({ ...user, color: this.getRandomColor() }));
          this.displayUsersOneByOne();
        } else {
          console.error('API returned non-200 status:', result.status);
          this.error = 'Error fetching user information. Please try again later.';
        }
      } catch (error) {
        console.error('Error fetching user information:', error);
        this.error = "Oops! We couldn't find anyone from that company. Please try different domain.";
      }
    },
    getRandomColor() {
      const colors = ['#D6CDEA', '#87DFE9', '#00ABC5', '#CAF1DE'];
      return colors[Math.floor(Math.random() * colors.length)];
    },
    async displayUsersOneByOne() {
      for (let i = 0; i < this.users.length; i++) {
        await new Promise(resolve => setTimeout(resolve, 300));
        this.users[i].loader = false;
      }
      this.loader = false;
    },
    startEngine() {
      let kk = JSON.stringify(this.users);
      localStorage.setItem("users", kk);
      this.$router.push({ name: 'PhishingChannels', params: { data: kk } });
    },
    generateRandomID() {
      let res = this.makeid(7);
      localStorage.setItem("uuid", res);
    },
    makeid(length) {
      let result = '';
      const characters = '123456789';
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
      return result;
    },
  },
};
</script>
