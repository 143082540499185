<template>
  <section class="section_wh channelSection" id="section4">
    <div class="userstrip" :class="{ 'disabled': sortedArray.length < 12 }">
      <ul class="fetchedUsers" id="donateList">
        <li v-for="(user, index) in users.slice(0, 5)" :key="index">
          <input type="radio" :id="user.id" name="amount" v-model="user_id"
          :value="user.id"
          @change="handleUserSelection(user)"/>
          <label :for="user.id">
            <div class="userCard">
            <div class="userCardheader" :style="{ backgroundColor: user.color }"></div>
            <div class="userCard-body">
              <img v-if="user.photo_url === ''" src="../../public/static/images/userdefault.png" class="userimage" alt="">
              <img v-else :src="user.photo_url" class="userimage" alt="">
              <div class="text-center">
                <h5 class="userCard-title">{{ user.name }}</h5>
                <div class="usertitle opacity-75">{{ user.title }}</div>
                <div class="opacity-75 line_height_18">
                  <div class="block text-capitalize">{{ user.subdepartments && user.subdepartments.length > 0 ? user.subdepartments[0].replace(/_/g, ' ') : '' }}</div>{{ user.country }}
                </div>
              </div>
            </div>
          </div>
          </label>
        </li>
      </ul>
    </div>
    <div class="text-center progresssection" v-if="sortedArray.length < 12">
      <div class="blinkcontainer">
        <div class="dot"></div>
        <div class="pulse"></div>
      </div>
      <strong class="prog_counter">{{ roundNumber(progress, 0) }}%</strong>
      <div class="progress">
        <div class="progress-bar" role="progressbar" :style="{ width: progress + '%' }" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
      <div class="mt5">
        <div class="loaderText_change">
          <div v-if="sortedArray.length < 3 ">Still working… take a deep breath and relax</div>
          <div v-if="sortedArray.length > 3 && sortedArray.length < 6">Why not stretch your legs while you wait?</div>
          <div v-if="sortedArray.length > 6 && sortedArray.length < 9">Take a deep breath, relax and smile :-)</div>
          <div v-if="sortedArray.length > 9">Did you drink water today? Go gulp a glass of water while I’m still working…</div>
        </div>
        <div class="lightText">It should take 90 to 120 seconds to generate precision strikes</div>
      </div> 
    </div>
    <div v-if="sortedArray.length != 0" class="nav_tabs_ai  clearfix">
      <div  class="nav nav-tabs" id="nav-tab" role="tablist" style="position: relative;max-width: 1100px;margin: auto;z-index: 1000;justify-content: center;">
        <button class="nav-link active" id="email-tab" data-toggle="tab" data-target="#email" type="button" role="tab" aria-controls="email" aria-selected="true">Email</button>
        <button class="nav-link" id="teams-tab" data-toggle="tab" data-target="#teams" type="button" role="tab" aria-controls="teams" aria-selected="false">Teams</button>
        <button class="nav-link" id="whatsapp-tab" data-toggle="tab" data-target="#whatsapp" type="button" role="tab" aria-controls="whatsapp" aria-selected="false">WhatsApp</button>
      </div>
    </div>
    <div class="tab-content tab_content_ai" id="nav-tabContent">
      <div class="tab-pane fade show active" id="email" role="tabpanel" aria-labelledby="email-tab">
        <div class="ai_result_layout">
          <div v-if="sortedArray.length == 0">
            <div v-for="index in 12" :key="index" class="template-loader">
              <div class="dateplaceholder"></div>
              <div class="loader_con">
                <div class="n_lo w-50 m-0"></div>
                <div class="n_lo w-40 mx-0"></div>
                <div class="temp_load"></div>
              </div>
            </div>
          </div>
          <div class="results_ai" v-for="(result,index) in sortedArray" :key="index">
            <span class="indicator"></span>
            <div class="result_m" v-if="result.key === 0">January<span>2024</span></div>
            <div class="result_m" v-if="result.key === 1">February<span>2024</span></div>
            <div class="result_m" v-if="result.key === 2">March<span>2024</span></div>
            <div class="result_m" v-if="result.key === 3">April<span>2024</span></div>
            <div class="result_m" v-if="result.key === 4">May<span>2024</span></div>
            <div class="result_m" v-if="result.key === 5">June<span>2024</span></div>
            <div class="result_m" v-if="result.key === 6">July<span>2024</span></div>
            <div class="result_m" v-if="result.key === 7">August<span>2024</span></div>
            <div class="result_m" v-if="result.key === 8">September<span>2024</span></div>
            <div class="result_m" v-if="result.key === 9">October<span>2024</span></div>
            <div class="result_m" v-if="result.key === 10">November<span>2024</span></div>
            <div class="result_m" v-if="result.key === 11">December<span>2024</span></div>
            <div class="result_cont" v-for="(template, index) in (result.data).filter(template => (template.TemplateType ==='email'))" :key="index">
              <div class="result_cont_h">
                <h5>{{ template.Topic }}</h5>
                <p>Subject: {{ template.Subject }}</p>
              </div>
              <div class="result_pannel">
                <div class="pan_h">
                  <span>Template</span>
                  <span></span>
                </div>
                <div class="pan_con" v-html="formatContent(template.Content)"></div>
              </div>
              <div class="topicKeyList" v-if="template.Description != ''" v-html="formatContent(template.Description)"></div>
            </div>
          </div>     
        </div>
      </div>
      <div class="tab-pane fade" id="teams" role="tabpanel" aria-labelledby="teams-tab">
        <div class="ai_result_layout">
          <div v-if="sortedArray.length == 0">
            <div v-for="index in 12" :key="index" class="template-loader">
              <div class="dateplaceholder"></div>
              <div class="loader_con">
                <div class="n_lo w-50 m-0"></div>
                <div class="n_lo w-40 mx-0"></div>
                <div class="temp_load"></div>
              </div>
            </div>
          </div>
          <div class="results_ai" v-for="(result,index) in sortedArray" :key="index">
            <span class="indicator"></span>
            <div class="result_m" v-if="result.key === 0">January<span>2024</span></div>
            <div class="result_m" v-if="result.key === 1">February<span>2024</span></div>
            <div class="result_m" v-if="result.key === 2">March<span>2024</span></div>
            <div class="result_m" v-if="result.key === 3">April<span>2024</span></div>
            <div class="result_m" v-if="result.key === 4">May<span>2024</span></div>
            <div class="result_m" v-if="result.key === 5">June<span>2024</span></div>
            <div class="result_m" v-if="result.key === 6">July<span>2024</span></div>
            <div class="result_m" v-if="result.key === 7">August<span>2024</span></div>
            <div class="result_m" v-if="result.key === 8">September<span>2024</span></div>
            <div class="result_m" v-if="result.key === 9">October<span>2024</span></div>
            <div class="result_m" v-if="result.key === 10">November<span>2024</span></div>
            <div class="result_m" v-if="result.key === 11">December<span>2024</span></div>
            <div class="result_cont" v-for="(template, index) in (result.data).filter(template => (template.TemplateType === 'teams') && template.Content.trim() !== '')" :key="index">
              <div class="result_cont_h">
                <h5>{{ template.Topic }}</h5>
              </div>
              <div class="result_pannel">
                <div class="pan_h">
                  <span>Message</span>
                  <span></span>
                </div>
                <div class="pan_con" v-html="formatContent(template.Content)"></div>
              </div>
              <div class="topicKeyList" v-if="template.Description != ''" v-html="formatContent(template.Description)"></div>
            </div>
          </div>     
        </div>
      </div>
      <div class="tab-pane fade" id="whatsapp" role="tabpanel" aria-labelledby="whatsapp-tab">
        <div class="ai_result_layout">
          <div v-if="sortedArray.length == 0">
            <div v-for="index in 12" :key="index" class="template-loader">
              <div class="dateplaceholder"></div>
              <div class="loader_con">
                <div class="n_lo w-50 m-0"></div>
                <div class="n_lo w-40 mx-0"></div>
                <div class="temp_load"></div>
              </div>
            </div>
          </div>
          <div class="results_ai" v-for="(result,index) in sortedArray" :key="index">
            <span class="indicator"></span>
            <div class="result_m" v-if="result.key === 0">January<span>2024</span></div>
            <div class="result_m" v-if="result.key === 1">February<span>2024</span></div>
            <div class="result_m" v-if="result.key === 2">March<span>2024</span></div>
            <div class="result_m" v-if="result.key === 3">April<span>2024</span></div>
            <div class="result_m" v-if="result.key === 4">May<span>2024</span></div>
            <div class="result_m" v-if="result.key === 5">June<span>2024</span></div>
            <div class="result_m" v-if="result.key === 6">July<span>2024</span></div>
            <div class="result_m" v-if="result.key === 7">August<span>2024</span></div>
            <div class="result_m" v-if="result.key === 8">September<span>2024</span></div>
            <div class="result_m" v-if="result.key === 9">October<span>2024</span></div>
            <div class="result_m" v-if="result.key === 10">November<span>2024</span></div>
            <div class="result_m" v-if="result.key === 11">December<span>2024</span></div>
            <div class="result_cont" v-for="(template, index) in (result.data).filter(template => (template.TemplateType ==='sms') && template.Content.trim() !== '')" :key="index">
              <div class="result_cont_h">
                <h5>{{ template.Topic }}</h5>
              </div>
              <div class="result_pannel">
                <div class="pan_h">
                  <span>Message</span>
                  <span></span>
                </div>
                <div class="pan_con" v-html="formatContent(template.Content)"></div>
              </div>
              <div class="topicKeyList" v-if="template.Description != ''" v-html="formatContent(template.Description)"></div>
            </div>
          </div>     
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from 'axios';
export default {
  name: 'PhishingChannels',
  data() {
    return {
      templateArray: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      resultArray: [],
      sortedArray: [],
      companyDomain: '',
      user_id:'',
      users: [],
      templateloader: true,
      intr: "",
      xxx: "",
      progress:0
    }
  },
  created() {
    this.companyDomain = localStorage.getItem('domainName');
    let ccc = this.$route.params.data
    if (!ccc) {
      let userdata = localStorage.getItem('users');
      this.users = JSON.parse(userdata);
    }
    else {
      this.users = JSON.parse(ccc);
    }
    this.fetchUserInfo(this.users);
  },
  methods: {
    roundNumber(number, decimals) {
    const multiplier = Math.pow(10, decimals || 0);
    return Math.round(number * multiplier) / multiplier;
  },
    handleUserSelection(user) {
      this.progress = 0
      this.user_id = user.id;
      this.resultArray = []
      this.sortedArray = []
      this.startEngine();
      this.moveUserPosition();
      this.setDefaultTab();
    },
    setDefaultTab() {
      const emailTab = document.getElementById('email-tab');
      if (emailTab) {
        emailTab.click();
      }
    },
    async fetchUserInfo(users) {
      try {
        if (!users) {
          let result = await axios.get(`userInfo?company=${this.companyDomain}`);
          this.users = result.data
          if (this.users.length ) {
            this.user_id = this.users[2].id
            this.startEngine();
          }
        } else {
          this.user_id = users[2].id
          this.startEngine();
        }
      } catch (error) {
        console.error('Error fetching user information:', error);
      }
    },
    moveUserPosition() {
      const donateList = document.getElementById('donateList');
      const selectedElement = document.querySelector('.fetchedUsers input[type="radio"]:checked').parentNode;
      const currentIndex = Array.from(donateList.children).indexOf(selectedElement);
      const offset = Math.floor(donateList.children.length / 2) - currentIndex;
      donateList.style.transform = `translateX(${offset * 130}px)`;
    },
    formatContent(content) {
      return content.replace(/\n/g, '<br>');
    },
    async startEngine() {
      this.sortedArray=[];
      try {
        let uuid = localStorage.getItem("uuid")
        let result = await axios.get(`startengine?uuid=${uuid}&id=${this.user_id}&company=${this.companyDomain}`);
        if (result.data === 'OK') {
          this.xxx = this.user_id
          this.startFetchingTemplate();
        }
      }
      catch (error) {
        console.error('Engine not started', error);
      }
    },
    async startFetchingTemplate() { 
      let that = this;
      const processedPositions = new Set();
      clearInterval(this.intr);
      this.intr = setInterval(async function () {
        for (let i = 0; i < that.templateArray.length; i++) {
          if (that.xxx != that.user_id) {
            break;
          }
          if (!processedPositions.has(i)) {
            let tempResult = await that.fetchTemplates(that.templateArray[i]);
            if (Object.keys(tempResult.data).length !== 0) {
              let obj = {};
              obj.key = i;
              obj.data = tempResult.data;
              that.resultArray.push(obj);
              that.resultArray.sort((a, b) => a.key - b.key);
              that.sortedArray =  that.resultArray
              processedPositions.add(i);
              that.progress = (processedPositions.size / that.templateArray.length) * 100;
            }
          }
        }
        if (processedPositions.size === that.templateArray.length) {
          clearInterval(that.intr);
        }
      }, 5000);
    },
    async fetchTemplates(i) {
      try {
        let uuid =localStorage.getItem("uuid")
         let tempResult = await axios.get(`engineprogress?pos=${i}&uuid=${uuid}`);
         return tempResult;
      } catch (error) {
        console.error('Error fetching user information:', error);
      }
    }
  }
}
</script>
